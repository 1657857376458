import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Button, Table, UncontrolledTooltip, Badge } from 'reactstrap'
import { EnumQuery } from '../Functions/querys'
import CarNotes from '../Material/CarNotes'
import UpdateCarInfo from '../Material/UpdateCarInfo'
import CarModelGroupIcon from './CarModelGroupIcon'
import renderEnumToInt from '../Functions/renderEnumToInt'
import toTitleCase from '../Functions/toTitleCase'
import UpdateReservationInfo from '../Reservations/UpdateReservationInfo'
import DriverNotes from '../ApplicationsNew/DriverNotes'
import CarEquipmentModal from './CarEquipmentModal'
import CarInfoModal from './CarInfoModal'
import CarPricingModal from './CarPricingModal'
import gql from 'graphql-tag'
import moment from 'moment'
import Lightbox from "react-awesome-lightbox"
import "react-awesome-lightbox/build/style.css"
import InfoModal from "../Material/InfoModal"
import DatetimeRenderer from "../Material/DatetimeRenderer"
import ReservationModalNew from "../Reservations/Modals/ReservationModalNew"
import "../Material/ListingHeader.css"
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
import { ReactComponent as CarEquipments } from '../assets/images/icons/car-equipments.svg'
import MassMessageModal from "../Material/MassMessageModal"
import DocumentModal from "../Material/DocumentModal/DocumentModal"
import { ReactComponent as InOffice } from "../assets/images/icons/in-office.svg"
import { ReactComponent as ArgyleIcon } from "../assets/images/icons/argyle.svg"
import { ReactComponent as LocationIcon } from "../assets/images/icons/location-icon.svg"

const AllReservationsQuery = gql`
  query AllReservationsQuery(
    $cursor: String
    $first: Int
    $searchTerm: String
    $orderBy: [String]
    $rep: ID
    $status: String
    $hasCar: Boolean
    $driverType: String
    $pickupDate_Lte: DateTime
    $pickupDate_Gte: DateTime
    $pickupDateRange: String
    $pickupType: [String]
    $pickUpLocation: [String]
    $carReadyStatus: Boolean    
    $agreementType: String
    $preferredCar: String
    $driverId: Int 
    $hasPromo: Boolean
    $awaitingReservationView: Boolean!
    $carModelNames: [String]
  ) {
    reservations: allReservations(
      first: $first
      orderBy: $orderBy
      after: $cursor
      rep: $rep
      searchTerm: $searchTerm
      status: $status
      hasCar: $hasCar
      driverType: $driverType
      pickupDate_Lte: $pickupDate_Lte
      pickupDate_Gte: $pickupDate_Gte
      pickupDateRange: $pickupDateRange
      pickupType: $pickupType
      pickUpLocation: $pickUpLocation
      carReadyStatus: $carReadyStatus
      agreementType: $agreementType
      preferredCar: $preferredCar
      hasPromo: $hasPromo
      driverPk: $driverId
      carModelNames: $carModelNames
    ) {
      edges {
        node {
          id
          pk
          dateAdded
          preferredCar
          pickupDate
          notes
          pickupType
          isSplit
          branch{
            id
            name
            branchSettings {
             edges {
               node {
                 id
                  key {
                    id
                    name
                  }
               }
             }
           }
          }
          pickUpLocation{
            id
            name
          }
          rep{
            id
            username
          }
          reservationpriceSet{
            edges {
              node {
                id
                price
                startDate
                maximumPrice
                minimumPrice
                interval
                intervalUnit
                isActive
              }
            }
          }
          driver {
            id
            name
            dmvLicense
            tlcLicense
            isTlc
            email
            phone
            preferredLanguage
            stage
            balance
            inOffice
            argyleAccountId
            activeInsurance {
                id
            }
          }
          car @skip(if: $awaitingReservationView) {
            id
            pk
            hasOpenIssues
            year
            carModel{
                id
                make
                name
                series
                groupType{
                    id
                    name
                }
            }
            plate{
                id
                dmvPlate
                fhv
                fhvExpiration      
                owner{
                    id
                    name
                }
            }
            location
            color
            notes
            stage
            lastCheckout{
                id
                dateAdded
                carpicturesSet {
                    edges {
                        node {
                            id
                            pictureUrl
                            description
                        }
                    }
                }
            }
            currentAgreement{
                id
                driver{
                    id
                    name
                }
                currentReturn{
                    id
                    scheduledDate
                    stage
                }
            }
            equipmentSet(removed:false){
                edges {
                    node {
                        id
                        dateAdded
                        removed
                        equipmentItems{
                            name
                        }
                    }
                }
            }
            prices(isDefault:true, isActive:true){
                edges {
                    node {
                        id
                        title
                        price
                        interval
                        intervalUnit
                        minimumPrice
                        maximumPrice
                        agreementType{
                            id
                            name
                        }
                    }
                }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        length
      }
    }
  }
`

const assignDriverToRepMutation = gql`
mutation CreateNewAssignment ($repID:ID!, $drivers:ID!){
    createAssignment(input:{repId:$repID, drivers:[$drivers]}){
        assignment{
            id
        }
    }
}`

const tableHeaders = [
    { id: "date_added", name: "Date Added", sortable: true, views: ["inactive"] },
    { id: "driver__id", name: "Driver Info", sortable: true, views: ["active", "all", "inactive"] },
    { id: "pickup_date", name: "Reservation Info", sortable: true, views: ["active", "all", "inactive"] },
    { id: "car__id", name: "Car Info", sortable: true, views: ["active", "all"] },
    { id: "car_notes", name: "Car Notes", sortable: false, views: ["active"] },
    { id: "preferredCar", name: "Preferred Car", sortable: false, views: ["inactive", "all"] },
    { id: "pickUpLocation", name: "Pickup Location", sortable: false, views: ["inactive"] },
    { id: "notes", name: "Reservation Notes", sortable: false, views: ["active", "all", "inactive"] },
    { id: "weekly_charge", name: "Pricing", sortable: false, views: ["active"] },
    { id: "assignedTo", name: "Assigned To", sortable: false, views: ["all", "inactive"] },
    { id: "action", name: "Actions", sortable: false, views: ["active", "all"] }
]
let currentDate = moment().tz("America/New_York").format("MMM D 'YY h:mm A")
let today = moment(currentDate)

class ResevationsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            location: null,
            loading: false,
            error: "",
            activeIndex: 0,
            images: null,
            selectedDrivers: [],
            equipmentModalPopover: ""
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    getReservationPickupState = (state) => {
        if (!this.props.reservationPickupStates) {
            return "---"
        }
        let sobj = this.props.reservationPickupStates.states.filter(s => s.name === state)
        return sobj.length ? sobj[0].description : "---"
    }

    renderReservationPrices = (defaultPrice, activePrice, carPk, allPrices) => {
        return <div className="view-prices">
            <CarPricingModal prices={allPrices} carPk={carPk} title={"All Reservation Prices"} iconWidth="20" />
            <span className="car-pricing-default">
                {activePrice && activePrice.price ?
                    <span className={activePrice.price < activePrice.minimumPrice ? "text-danger" : (defaultPrice && defaultPrice.price && activePrice.price < defaultPrice.price) ? "text-warning" : "text-success"}>
                        {("$" + activePrice.price + "/- " + (activePrice.interval > 1 ? activePrice.interval + " " : "") + activePrice.intervalUnit)}
                    </span> : <span className="text-danger" >No Price Selected!</span>}
                <br /><small>{activePrice && activePrice.minimumPrice ?
                    ("$" + activePrice.minimumPrice + (activePrice.maximumPrice ? " - $" + activePrice.maximumPrice : "")) : ""}
                </small>
            </span>
        </div>
    }
    getCarReturnState = (state) => {
        if (!this.props.carReturnStates) {
            return "---"
        }
        let sobj = this.props.carReturnStates.states.filter(s => s.name === state)
        return sobj.length ? sobj[0].description : "---"
    }

    viewImages(object, i) {
        let tmpArr = []
        if (object && object.carpicturesSet && object.carpicturesSet.edges && object.carpicturesSet.edges.length > 0) {
            const toBeAdded = object.carpicturesSet.edges.map((pic, i) => ({ url: pic.node.pictureUrl, title: pic.node.description }))
            tmpArr = [...tmpArr, ...toBeAdded]
        }
        this.setState({ images: tmpArr })
        this.toggleModal("viewImages" + i)
    }
    updateSelected = (value) => {
        let selectedDrivers = this.props.selectedDrivers
        if (selectedDrivers && selectedDrivers.map(item => item.id).includes(value.id)) {
            selectedDrivers = selectedDrivers.filter(item => item.id !== value.id)
        } else {
            selectedDrivers.push(value)
        }
        this.props.setSelectedDrivers(selectedDrivers)
    };

    toggleSelectAllClick = () => {
        let selectedDrivers = []
        if (this.props.reservations && this.props.reservations.edges && this.props.reservations.edges.length > 0 && !this.state.selectAll) {
            selectedDrivers = this.props.reservations.edges.map(n => n.node ? n.node : "")
            this.setState({ selectAll: true })
        } else {
            this.setState({ selectAll: false })
        }
        this.props.setSelectedDrivers(selectedDrivers)
    };
    unassignReps = () => {
        let input = {
            drivers: this.props.selectedDrivers,
            repID: "any"
        }
        this.props.client.mutate({
            mutation: assignDriverToRepMutation,
            variables: input
        }).then((result) => {
            this.props.refetchReservationsQuery()
        })
    }
    durationDaysDifferntialHandler = (dateAdded) => {
        let differnce = today.diff(moment(dateAdded).tz("America/New_York").format("MMM D 'YY h:mm A"), 'days')
        return differnce
    }
    renderTimestamp = (dateTime) => {
        let daysDifference = this.durationDaysDifferntialHandler(dateTime)
        let prefix = ""
        if (daysDifference < 1) {
            // less than one day
            prefix = "Less Than a Day"
        } else if (daysDifference <= 30 && daysDifference > 1) {
            // less than 30 days ago
            prefix = `${daysDifference} Days`
        } else if (daysDifference > 30) {
            // more than 30 days ago
            prefix = `More Than 30 Days`
        } else {
            prefix = daysDifference
        }
        return prefix
    }
    componentDidUpdate(prevProps) {
        if (prevProps.refetchReservations !== this.props.refetchReservations && this.props.refetchReservations) {
            this.props.refetchReservationsQuery()
            this.props.setRefetchQuery()
        }
    }
    render() {
        return (<>
            <PrimaryListingContainer listLoading={this.props.loading || this.state.loading} totalFetchedRows={this.props.reservations && this.props.reservations.edges.length} pageInfo={this.props.reservations && this.props.reservations.pageInfo} loadMoreEntries={this.props.reservations && this.props.reservations.pageInfo && this.props.reservations.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchReservationsQuery}>
                <Table responsive>
                    <thead>
                        <tr>
                            <th onClick={this.toggleSelectAllClick} className="select-all-checkbox"> {this.state.selectAll ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                            {tableHeaders.filter(header => header.views.includes(this.props.selectedTab)).map(tableHeader => tableHeader.sortable ?
                                <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{this.props.orderBy.some(order => order.includes(tableHeader.id)) ? <i className={"fa " + (this.props.orderBy.includes(tableHeader.id) ? 'fa-sort-asc' : 'fa-sort-desc')}></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{tableHeader.name}</th> :
                                <th key={tableHeader.id}>{tableHeader.name}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.reservations && this.props.reservations.edges && this.props.reservations.edges.length > 0 ? this.props.reservations.edges.map((reservation, i) =>
                            <tr key={i + 1} className={reservation.node.driver && reservation.node.driver.inOffice && this.props.activeTab !== "all" ? "driver-in-office-row" : ""}>
                                <td scope="row" className="listing-checkbox-wrapper">
                                    {!this.props.selectedDrivers.map(item => item.id).includes(reservation.node.id) && <strong className="listing-counter">{i + 1}</strong>}
                                    <span className="listing-checkbox" onClick={() => this.updateSelected(reservation.node)}>
                                        {this.props.selectedDrivers.map(item => item.id).includes(reservation.node.id) ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o unchecked" aria-hidden="true"></i>}
                                    </span>
                                </td>
                                {this.props.selectedTab === "inactive" && <td>
                                    {this.renderTimestamp(reservation.node.dateAdded)}
                                    <br />
                                    <small>
                                        <DatetimeRenderer datetime={reservation.node.dateAdded} placement="right" />
                                    </small>
                                </td>}
                                <td>
                                    <small>{reservation.node.driver.dmvLicense || "--"}</small>
                                    {reservation.node.driver && reservation.node.driver.inOffice && this.props.activeTab !== "all" &&
                                        <>
                                            <InOffice id={"inOffice" + i} width={25} height={25} fill="#198754" />
                                            <UncontrolledTooltip target={"inOffice" + i}>Driver in Office</UncontrolledTooltip>
                                        </>
                                    }
                                    {reservation.node.driver && (reservation.node.branch && reservation.node.branch.branchSettings &&
                                        reservation.node.branch.branchSettings.edges && reservation.node.branch.branchSettings.edges.length > 0 &&
                                        reservation.node.branch.branchSettings.edges.some(setting => setting.node.key && setting.node.key.name === "ENABLE_ARGYLE_WORKFLOW")) &&
                                        (!reservation.node.driver.argyleAccountId || reservation.node.driver.argyleAccountId === null) &&
                                        <>
                                            &nbsp;<ArgyleIcon id={"argyle" + i} width={18} height={18} fill="#198754" />
                                            <UncontrolledTooltip target={"argyle" + i}>Argyle Account Is Not Connected</UncontrolledTooltip>
                                        </>
                                    }
                                    <br />
                                    <small>
                                        {reservation.node.driver.stage == 6 && <>
                                            <i id={"driverIsActive" + i} className="fa fa-circle text-success" ></i>&nbsp;&nbsp;
                                            <UncontrolledTooltip placement="right" target={"driverIsActive" + i}>Active Driver</UncontrolledTooltip>
                                        </>}
                                        {!reservation.node.driver.activeInsurance || !reservation.node.driver.activeInsurance.length > 0 && <>
                                            <i id={"driverWithoutInsurance" + i} className="fa fa-exclamation-triangle text-warning" ></i>&nbsp;&nbsp;
                                            <UncontrolledTooltip placement="right" target={"driverWithoutInsurance" + i}>No Active Insurance!</UncontrolledTooltip>
                                        </>}
                                    </small>
                                    <a className="bos-custom-link" color="primary" id={"driverInfo" + i} href={"/driver/" + reservation.node.driver.id} target="_blank">
                                        {reservation.node.driver.name}
                                    </a>
                                    {this.state.openModal === "MassMessageModal" + i && <MassMessageModal handleClose={() => this.toggleModal("MassMessageModal" + i)} open={this.state.openModal === "MassMessageModal" + i} drivers={[reservation.node.driver.id]} />}
                                    {this.state.openModal === "driverDocuments" + i && <DocumentModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "driverDocuments" + i} objectType="driver" id={reservation.node.driver.id} currentUser={this.props.currentUser} />}
                                    {!this.state.openModal && <UncontrolledTooltip delay={{ "show": 400, "hide": 200 }} target={"driverInfo" + i} placement="right" autohide={false} className="bos-custom-tooltip">
                                        <label>{reservation.node.driver.name}</label>
                                        <span className="bos-custom-tooltip-header">
                                            <i className="fa fa-at" aria-hidden="true"></i>&nbsp;&nbsp;{reservation.node.driver.email}<br />
                                            <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;{reservation.node.driver.phone}<br />
                                        </span>
                                        <p className="bos-custom-tooltip-body">
                                            <b>Balance:</b> <span className={reservation.node.driver.balance && reservation.node.driver.balance > 0 ? "text-danger" : "text-success"}>${reservation.node.driver.balance}</span><br />
                                            {reservation.node.driver.isTlc && <><b>TLC #:</b> {reservation.node.driver.tlcLicense} <br /></>}
                                            <b>Stage:</b> {reservation.node.driver.stage} <br />
                                        </p>
                                        <span className="bos-custom-tooltip-footer">
                                            {reservation.node.driver && <><Button onClick={() => this.toggleModal("MassMessageModal" + i)}><i className="fa fa-comment-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>}
                                            {reservation.node.driver && <><Button onClick={() => this.toggleModal("driverDocuments" + i)}><i className="fa fa-folder-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>}
                                        </span>
                                    </UncontrolledTooltip>}
                                </td>
                                <td>
                                    {(this.state.openModal === "editReservation" + i && reservation.node.id) && <ReservationModalNew id={reservation.node.id} title="Update Reservation" open={this.state.openModal === "editReservation" + i} isUpdate={true} handleClose={() => this.toggleModal("")} currentUser={this.props.currentUser} />}
                                    <small className={new Date(reservation.node.pickupDate) < new Date() ? "text-danger" : ""}>Pickup @ {reservation.node.pickupDate ? <DatetimeRenderer datetime={reservation.node.pickupDate} /> : "--"}</small>{reservation.node.isSplit && <small>{reservation.node.isSplit && " | SPLIT"}</small>}
                                    <br />
                                    <a className="bos-custom-link" id={"reservationEdit" + i} role="button" tabIndex="0" onClick={() => this.toggleModal("editReservation" + i)}>
                                        {reservation.node.pickupType ? this.getReservationPickupState(reservation.node.pickupType) : "--"}
                                    </a>
                                    <UncontrolledTooltip placement="right" target={"reservationEdit" + i}>Edit</UncontrolledTooltip>
                                    <br />
                                    <small>
                                        <Badge className="bos-badge-blue">{reservation.node.branch.name}</Badge>
                                    </small>

                                </td >
                                {
                                    ["active", "all"].includes(this.props.selectedTab) && <td className="py-3">
                                        {reservation.node.car ? <div className="cars-wrapper d-flex justify-content-between">
                                            <div className='d-flex'>
                                                <div className="car-id-detail align-self-center">
                                                    <CarModelGroupIcon carGroup={reservation.node.car.carModel.groupType ? reservation.node.car.carModel.groupType.name : null} carColor={reservation.node.car.color} id={reservation.node.car.pk} key={i + 1} />
                                                    <br />
                                                    <span>
                                                        {reservation.node.car.pk}
                                                    </span>
                                                </div>
                                                <div className="car-name-years align-self-center" id={"carInfo" + reservation.node.pk}>
                                                    <small>
                                                        {reservation.node.car.year} | Stage {renderEnumToInt(reservation.node.car.stage)}
                                                    </small><br />
                                                    {reservation.node.car.hasOpenIssues && <><i className="fa fa-wrench" style={{ color: "#DC3546" }} aria-hidden="true"></i>&nbsp;&nbsp;</>}
                                                    {(reservation.node.car.stage == 10 || (reservation.node.car.currentAgreement && reservation.node.car.currentAgreement.id)) ? <>
                                                        <i id={"carIsActive" + i} className="fa fa-circle text-success" ></i>
                                                        <UncontrolledTooltip placement="right" target={"carIsActive" + i}>Active</UncontrolledTooltip>
                                                        &nbsp;&nbsp;
                                                    </> : reservation.node.car.lastCheckout ? <><i className={"fa fa-file-image-o text-success"} aria-hidden="true"></i>&nbsp;&nbsp;</> : ""}
                                                    <a className="bos-custom-link" color="primary" href={"/car/" + reservation.node.car.pk} target="_blank">
                                                        {toTitleCase(reservation.node.car.carModel.make)} {toTitleCase(reservation.node.car.carModel.name)}
                                                    </a>
                                                </div>
                                                {this.state.openModal === "viewImages" + i && !reservation.node.car.lastCheckout &&
                                                    <InfoModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "viewImages" + i} title={"Checkout Images"} message={"No checkout images for car " + reservation.node.car.pk} />
                                                }
                                                {this.state.openModal === "viewImages" + i && reservation.node.car.lastCheckout &&
                                                    <Lightbox images={this.state.images} onClose={() => this.toggleModal("viewImages" + i)} />
                                                }
                                                {this.state.openModal === "carEquipments" + i && <CarEquipmentModal carEquipments={reservation.node.car.equipmentSet} id={reservation.node.pk} key={i + 1} open={this.state.openModal === "carEquipments" + i} handleClose={() => this.toggleModal("")} />}
                                                {this.state.openModal === "CarInfo" + reservation.node.car.pk && <CarInfoModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "CarInfo" + reservation.node.car.pk} id={reservation.node.car.pk} carPk={reservation.node.car.pk} carId={reservation.node.car.id} refetchQuery={this.props.refetchReservationsQuery} />}
                                                {!this.state.openModal && <UncontrolledTooltip delay={{ "show": 400, "hide": 200 }} target={"carInfo" + reservation.node.pk} placement="right" autohide={false} className="bos-custom-tooltip">
                                                    <label>{reservation.node.car.year} {toTitleCase(reservation.node.car.carModel.make)} {toTitleCase(reservation.node.car.carModel.name)}</label>
                                                    <span className="bos-custom-tooltip-header">
                                                        <UncontrolledTooltip target={"series" + reservation.node.pk} autohide={false}>Series</UncontrolledTooltip>
                                                        <UncontrolledTooltip target={"group" + reservation.node.pk} autohide={false}>Group</UncontrolledTooltip>
                                                        <i className="fa fa-car" id={"series" + reservation.node.pk} aria-hidden="true" />&nbsp;&nbsp;{reservation.node.car.carModel.series ? reservation.node.car.carModel.series : "--"}<br />
                                                        <i className="fa fa-hashtag" id={"group" + reservation.node.pk} aria-hidden="true" />&nbsp;&nbsp;{reservation.node.car.carModel.groupType ? reservation.node.car.carModel.groupType.name : "--"} <br />
                                                    </span>
                                                    <p className="bos-custom-tooltip-body">
                                                        {(reservation.node.car.stage == 10 || (reservation.node.car.currentAgreement && reservation.node.car.currentAgreement.id)) && <>
                                                            {reservation.node.car.currentAgreement && reservation.node.car.currentAgreement.currentReturn ?
                                                                <>
                                                                    <b>Returning Driver:</b> <a color="primary" href={"/driver/" + reservation.node.car.currentAgreement.driver.id} target="_blank">{reservation.node.car.currentAgreement.driver.name ? reservation.node.car.currentAgreement.driver.name : "--"}</a><br />
                                                                    <b>Returning Date:</b> <span className={moment().diff(moment(reservation.node.car.currentAgreement.currentReturn.scheduledDate), 'days') > 0 ? "text-danger" : ""}>{reservation.node.car.currentAgreement.currentReturn.scheduledDate ? moment(reservation.node.car.currentAgreement.currentReturn.scheduledDate).tz("America/New_York").format('lll') : "--"}</span><br />
                                                                    <b>Return Stage:</b> {reservation.node.car.currentAgreement.currentReturn.stage ? this.getCarReturnState(reservation.node.car.currentAgreement.currentReturn.stage) : "--"}<br />
                                                                </>
                                                                : reservation.node.car.currentAgreement ? <><b>Returning Driver:</b> {"No Return Found!"} <br /></> : ""}
                                                        </>}
                                                        <b>Plate:</b> {reservation.node.car.plate && reservation.node.car.plate.dmvPlate || "--"}<br />
                                                        <b>Color:</b> {reservation.node.car && reservation.node.car.color || "--"} <br />
                                                    </p>
                                                    <span className="bos-custom-tooltip-footer">
                                                        <Button onClick={() => this.viewImages(reservation.node.car.lastCheckout, i)} ><i className={"fa fa-file-image-o fa-lg " + (!reservation.node.car.lastCheckout ? "text-danger" : "text-success")} aria-hidden="true"></i></Button>&nbsp;&nbsp;
                                                        <Button onClick={() => this.toggleModal("carEquipments" + i)}>
                                                            <UncontrolledTooltip placement="bottom" target={"equipment" + reservation.node.pk}>Installed Equipments</UncontrolledTooltip>
                                                            <a id={"equipment" + reservation.node.pk} type="button" className="cargadgets-button" >
                                                                <CarEquipments width={20} fill="#303e67" />
                                                            </a>
                                                        </Button>&nbsp;&nbsp;
                                                        <Button onClick={() => this.toggleModal("CarInfo" + reservation.node.car.pk)}>
                                                            <i className="fa fa-wrench fa-2x" style={reservation.node.car.hasOpenIssues ? { color: "#DC3546" } : { color: "#303e67" }} aria-hidden="true"></i>
                                                        </Button>
                                                    </span>
                                                </UncontrolledTooltip>}
                                            </div>
                                        </div> : <span >----</span>}
                                    </td>
                                }
                                {
                                    ["active"].includes(this.props.selectedTab) && <td>
                                        {reservation.node.car ? <div className="notes">
                                            {this.state.openModal === "carNotesHistory" + i &&
                                                <CarNotes car={reservation.node.car} target={"carNotesEdit" + i} open={this.state.openModal === "carNotesHistory" + i} handleClose={() => this.toggleModal("")} />
                                            }
                                            {this.state.openModal === "carNotesEdit" + i &&
                                                <UpdateCarInfo car={reservation.node.car} title="Edit Notes" name="notes" value={reservation.node.car.notes} target={"carNotesEdit" + i} type="text" open={this.state.openModal === "carNotesEdit" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationsQuery} />
                                            }

                                            <span id={"carNotesEdit" + i}>
                                                <small className="note-text">
                                                    {reservation.node.car.notes ? reservation.node.car.notes : "--"}
                                                </small><br />
                                                <i onClick={() => this.toggleModal("carNotesEdit" + i)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                                <i onClick={() => this.toggleModal("carNotesHistory" + i)} className={"fa fa-history"}></i>&nbsp;&nbsp;
                                                <UncontrolledTooltip placement="right" target={"carNotesEdit" + i}>{reservation.node.car.notes ? reservation.node.car.notes : "--"}</UncontrolledTooltip>
                                            </span>
                                        </div> : <span>----</span>}
                                    </td>
                                }
                                {
                                    ["inactive", "all"].includes(this.props.selectedTab) && <td>
                                        <small>{reservation.node.preferredCar || "--"}</small>
                                    </td>
                                }
                                {
                                    this.props.selectedTab === "inactive" && <td>
                                        {reservation.node.pickUpLocation && reservation.node.pickUpLocation.name || "--"}
                                    </td>
                                }
                                <td>
                                    <div className="notes">
                                        {this.state.openModal === i + "notesHistory" &&
                                            <DriverNotes driver={reservation.node.driver} noteType="RESERVATION" title="Reservation Notes History" objectId={reservation.node.id} target={"notes" + i} open={this.state.openModal === i + "notesHistory"} handleClose={() => this.toggleModal("")} placement={"left"} />}
                                        {this.state.openModal === "UpdateReservationInfo" + reservation.node.id &&
                                            <UpdateReservationInfo reservation={reservation.node} title="Edit Reservation Notes" name="notes" value={reservation.node.notes} target={"notes" + i} type="text" open={this.state.openModal === "UpdateReservationInfo" + reservation.node.id} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationsQuery} />}
                                        <span id={"notes" + i}>
                                            <small className="note-text">
                                                {reservation.node.notes ? reservation.node.notes : "--"}
                                            </small>
                                            <br />
                                            <i id={"notesEdit" + reservation.node.id} onClick={() => this.toggleModal("UpdateReservationInfo" + reservation.node.id)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                            <i onClick={() => this.toggleModal(i + "notesHistory")} className={"fa fa-history"}></i>&nbsp;&nbsp;
                                            <UncontrolledTooltip placement="right" target={"notes" + i}>{reservation.node.notes ? reservation.node.notes : "--"}</UncontrolledTooltip>
                                        </span>
                                    </div>
                                </td>
                                {
                                    ["active"].includes(this.props.selectedTab) && <td>
                                        {reservation.node.car ? this.renderReservationPrices(reservation.node.car.prices &&
                                            reservation.node.car.prices.edges && reservation.node.car.prices.edges.length > 0 &&
                                            reservation.node.car.prices.edges.filter(price =>
                                                price.node && price.node && price.node.agreementType &&
                                                reservation.node && reservation.node.agreementType &&
                                                price.node.agreementType.name === reservation.node.agreementType.name).length > 0 ?
                                            reservation.node.car.prices.edges.filter(price => price.node && price.node.agreementType.name === reservation.node.agreementType.name)[0].node : null, reservation.node.reservationpriceSet && reservation.node.reservationpriceSet.edges && reservation.node.reservationpriceSet.edges.length > 0 ? reservation.node.reservationpriceSet.edges[0].node : null, reservation.node.car.pk, reservation.node.reservationpriceSet) : <span>----</span>}
                                    </td>
                                }
                                {
                                    ["all", "inactive"].includes(this.props.selectedTab) && <td>
                                        <div className="d-flex">
                                            <span className='pr-3 align-self-center'>
                                                <i className={"fa fa-pencil fa-lg"} onClick={() => this.toggleModal("rep" + i)} id={"rep" + i}></i>
                                                {this.state.openModal === "rep" + i &&
                                                    <UpdateReservationInfo reservation={reservation.node} title="Assign Representative" name="representative" value={reservation.node.rep ? reservation.node.rep.username : null} target={"rep" + i} type="text" open={this.state.openModal === "rep" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationsQuery} groups={["Sales Team"]} />
                                                }
                                            </span>
                                            <span className="pl-3" style={{ borderLeft: "1px solid #e1e1e1" }}>
                                                <span key={"rep" + i}>{reservation.node.rep ? reservation.node.rep.username : "--"}</span>
                                            </span>
                                        </div>
                                    </td>
                                }
                                <td>
                                    <ul className="actions d-flex">
                                        {["all", "active"].includes(this.props.selectedTab) && <li className="align-self-center text-center">
                                            {reservation.node.car ? <>
                                                <a id={"LocationPopup" + i} href={`https://fleetsync.io/cars/${reservation.node.car.pk}?tab=Map`} target={"_blank"} rel="noopener noreferrer">
                                                    <LocationIcon width="20" fill="#303E67" />
                                                </a>
                                                <UncontrolledTooltip placement="top" target={"LocationPopup" + i}>Locate car on fleetSync</UncontrolledTooltip>
                                                <br /><small>{reservation.node.car.location}</small>
                                            </> : "----"}
                                        </li>}
                                    </ul>
                                </td>
                            </tr >
                        ) : <tr>
                            <td colSpan={10}>{this.props.loading ? "Loading..." : "No Reservation Found!"}</td>
                        </tr>}
                    </tbody >
                </Table >
            </PrimaryListingContainer >
        </>)
    }
}

export default compose(
    withApollo,
    graphql(EnumQuery, { props({ data: { carReturnStates, reservationPickupStates } }) { return { carReturnStates, reservationPickupStates } } }),
    graphql(AllReservationsQuery, {
        options: ({ searchTerm, status, hasCar, assignedTo, orderBy, pickupDateRange, pickupType, pickUpLocation, carReadyStatus, pickupDate_Lte, pickupDate_Gte, driverType, agreementType, preferredCar, driverId, hasPromo, selectedTab, carModelNames }) => ({
            variables: { searchTerm, rep: assignedTo, status, hasCar, orderBy, pickupDateRange, pickupType, pickUpLocation, carReadyStatus, pickupDate_Lte, pickupDate_Gte, first: 30, driverType, agreementType, preferredCar, driverId, hasPromo, awaitingReservationView: selectedTab === "inactive" ? true : false, carModelNames },
            notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only'
        }),
        // This function re-runs every time `data` changes, including after `updateQuery`,
        // meaning our loadMoreEntries function will always have the right cursor
        props({ data: { loading, reservations, fetchMore, searchTerm, variables, networkStatus } }) {
            return {
                loading, reservations, variables, networkStatus, refetchReservationsQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength =
                        reservations && reservations.edges ? reservations.edges.length : 30
                    return fetchMore({
                        query: AllReservationsQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.reservations.edges
                            const pageInfo = fetchMoreResult.reservations.pageInfo
                            return {
                                reservations: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.reservations.__typename,
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: AllReservationsQuery,
                        variables: {
                            cursor: reservations.pageInfo.endCursor,
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.reservations.edges
                            const pageInfo = fetchMoreResult.reservations.pageInfo
                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                reservations: {
                                    edges: [...previousResult.reservations.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.reservations.__typename,
                                },
                            }
                        },
                    })
                },
            }
        },
    })
)(ResevationsList)
